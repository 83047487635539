// @use '@carbon/react/scss/spacing' as *;

// .header_button {
//   min-width: $spacing-09;
//   min-height: $spacing-09;
// }

.cds--header {
  background-color: #3680AF;
  --cds-icon-secondary: white;
  --cds-icon-primary: white;
}

.cds--header__nav {
  background-color: #3680AF;
}

.cds--header__menu-bar{
  background-color: #3680AF;
}

.cds--header__menu-item {
  background-color: #3680AF !important;
  color: white !important;
}

.cds--header__menu-item:hover {
  background-color: #5b97bd !important;
  color: white !important;
}

.cds--header__menu-item--current {
  background-color: #E6F1F7 !important;
  color: #3680AF !important;
}

.cds--header__menu-item--current:hover {
  background-color: #d9e4ea !important;
  color: #3680AF !important;
}
.cds--header__menu-item--current:after {
  border: 0;
}

.cds--header__name {
  color: white !important;
}

.cds--side-nav {
  background-color: #3680AF;
}

.header_button {
  background-color: #3680AF;
}

