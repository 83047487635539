.cds--accordion__content{
    padding: 0;
}

.cds--date-picker.cds--date-picker--single .cds--date-picker__input {
    width: 100%;
}

.cds--date-picker {
    display: flex;
    width: 100%;
}

.cds--date-picker-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.cds--inline-notification__close-icon {
    fill: #5b97bd !important;
}

.cds--btn--tertiary{
    --cds-button-tertiary: #3680AF;
    --cds-button-tertiary-hover: #3680AF;
}



.cds--modal-container {
    position: static;
    width: 1312px; //video width 1280px + 2 rem padding (32px)
    height: auto;
    max-height: 90%;
}