@use '@carbon/react/scss/themes';
@use '@carbon/react/scss/theme';
@use '@carbon/styles';
@use '@carbon/themes/scss/tokens';
@use '@carbon/react/scss/spacing';
@use '@carbon/grid';
@use '@carbon/type';
@tailwind components;
// @tailwind base;
@tailwind utilities;


.themeWrapper{
  --cds-layer-selected-inverse: #3680AF;

}

html {
  // for datatable overflow-x
  // if this isnt in, the background color wont be themed
  // alternatives: position:absolute
  // please replace if you know of anything not this... interesting
  display: table;
  min-width: 100%;
}
